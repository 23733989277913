export const projectsData = [
  {
    _id: 11,
    project_name: "ALUCOBOND® Lebanon",
    favicon: "/assets/projects/aa-favicon.svg",
    url: "https://anidconstruction.com/",
    desktop: "/assets/projects/aa-website.jpg",
    phone: "/assets/projects/aa-website-ph.jpg",
    description: "UI/UX Design / Developement",
    design_skills: "Art direction / Logo design / Image refinement.",
    coding_tools: "HTML, CSS & React JS",
  },
  {
    _id: 12,
    project_name: "CHIRINE SAYEGH | Architect",
    favicon: "/assets/projects/cs-favicon.svg",
    url: "https://chirinesayegh.com/",
    desktop: "/assets/projects/cs-website.jpg",
    phone: "/assets/projects/cs-website-ph.jpg",
    description: "UI/UX Design / Developement",
    design_skills: "Art Direction / Logo design / Image refinement.",
    coding_tools: "HTML, CSS & React JS",
  },
  {
    _id: 13,
    project_name: "My Birthday Table",
    favicon: "",
    url: "https://my-birthday-table.netlify.app/",
    desktop: "/assets/projects/mybdaytable-app.jpg",
    phone: "/assets/projects/mybdaytable-app-ph.jpg",
    description: "UI/UX Design / Developement",
    design_skills: "Art direction / Logo design",
    coding_tools: "HTML, CSS & React JS",
  },
  {
    _id: 14,
    project_name: "Kareen Abdelnour | art",
    favicon: "/assets/projects/ka-favicon.svg",
    url: "https://kareenabdelnour.com",
    desktop: "/assets/projects/ka-website.jpg",
    phone: "/assets/projects/ka-website-ph.jpg",
    description: "UI/UX Design / Developement",
    design_skills: "Art direction / Logo design / Image refinement.",
    coding_tools: "HTML, CSS & JavaScript",
  },
];
